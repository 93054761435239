import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Planet } from 'react-planet';
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import About from './about.js';
import Publication from './publication.js';
import './general.css';
import background from './img/background.jpg';
import {Stack, Box} from '@mui/material';

class General extends Component{
	constructor(props)
	{
		super(props);
		this.selfieNum = 1;
		this.selfie = require('./img/selfie' + Math.floor(Math.random()*this.selfieNum) + '.jpg');
		console.log(this.selfie);
		this.xlBoundary = 1200;
		this.standardOrbitRadius = 200;
		this.originPosition = {
			mainStyle: {
				position: 'absolute', 
				top: '25vh', 
				left: '50vw', 
				marginTop:-Math.min(window.innerWidth * 0.25, 150),
				marginLeft:-Math.min(window.innerWidth * 0.25, 150),
				maxWidth: '100%', 
				alignItem: 'center',
				textAlign: 'center',
			}, 
			clickFlag: 0,
			aboutFlag: 0,
			pubFlag: 0,
			planetSize: Math.min(window.innerWidth * 0.5, 250),
			iconSize: Math.min(window.innerWidth * 0.1, 70),
			orbitRadius: Math.min(window.innerWidth * 0.4, this.standardOrbitRadius),
			bodyStyle: {
				backgroundImage:`url(${background})`,
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundAttachment: 'fixed',
				backgroundSize: 'cover',
			}};
		this.state = this.originPosition;
		this.emptyDiv = {position:'relative', width: 300, height: 400};

	}
	changeLayout (flag)
	{
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		if(flag)
		{	
			this.standardOrbitRadius = 187;
			this.setState({
				mainStyle: {
					position: 'relative', 
					left:'55%', 
					marginLeft:-Math.min(window.innerWidth * 0.3, 150),
				}, 
				bodyStyle: {
					backgroundImage:``,
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: 'fixed',
					backgroundSize: 'cover'
				}});
		}
		else
		{
			this.standardOrbitRadius = 200;
			this.setState(this.originPosition);
		}
		this.adjustPlanetSize();
			
	}
	componentDidMount() {
        this.adjustPlanetSize();
        window.addEventListener('resize', this.adjustPlanetSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustPlanetSize);
    }

	adjustPlanetSize = () => {
        const planetSize = Math.min(window.innerWidth * 0.5, 250);
		const iconSize = Math.min(window.innerWidth * 0.1, 70);
		const orbitRadius = Math.min(window.innerWidth * 0.4, this.standardOrbitRadius);
        this.setState({
			planetSize: planetSize,
			iconSize: iconSize,
			orbitRadius: orbitRadius
		});
		//console.log(planetSize, iconSize, orbitRadius);
    }

	clearFlag()
	{
		this.setState({aboutFlag: 0, pubFlag: 0});
	}

	basic (){
		return(
		<div style = {this.state.clickFlag? {}:{color: '#dfdfdf'}}>
			<h4>
				Anran XU (徐 安然)
			</h4>
			<h5>
				{'\n'}
				Ph.D. Student
			</h5>
			<h5>
				<a href='https://iis-lab.org/'>
					Interactive Intelligent Systems Lab
				</a>
			</h5>
			<h5>
				The University of Tokyo
			</h5>
			<h5>
				<strong>
					Contact
				</strong>
			</h5>
			<h5>
				anran[at]iis-lab[dot]org
			</h5>
			<h5>
				7-3-1 Hongo, Bunkyo-ku
			</h5>
			<h5>
				Tokyo, Japan
			</h5>
			<h5>
				113-0033
			</h5>
			<h5>
				Click the icons around my selfie for more information.
			</h5>
		</div>
		);
	}
	handleClick = (action, changeLayoutFlag=0, changeLayout=1) => {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		this.clearFlag();
		this.setState({ [action]: 1, clickFlag: 1 }, () => {
			if (changeLayoutFlag) {
				this.changeLayout(changeLayout);
			};
			if (window.innerWidth < this.xlBoundary) {
				if (action === 'aboutFlag') {
					const targetElement = document.getElementsByClassName('aboutMain')[0];
					if (targetElement) {
						targetElement.scrollIntoView();
					}
				} else if (action === 'pubFlag') {
					const targetElement = document.getElementsByClassName('publicationMain')[0];
					if (targetElement) {
						targetElement.scrollIntoView();
					}
				}
			}
		});
	}
	render(){
		for(var i in this.state.bodyStyle)
		{
			document.body.style[i] = this.state.bodyStyle[i];
		}
		return(
			<div>
				{this.state.clickFlag? 
				<Container>
					<Row>
						<Col lg={12} xl = {3}>
							<div style = {this.state.mainStyle}>
								<div style = {{height: 30}} ></div>
								<Planet
									centerContent={ <img
						            	src = {this.selfie}
						                style={{
						                    height: this.state.planetSize,
						                    width: this.state.planetSize,
						                    borderRadius: '50%',
						                    backgroundColor: '#aaaaaa',
						            }}/>}
									hideOrbit
									open
									dragablePlanet
			   						dragRadiusPlanet={20}
									orbitRadius={this.state.orbitRadius}
									bounceOnClose
									rotation={105}
									// the bounce direction is minimal visible
									// but on close it seems the button wobbling a bit to the bottom
									bounceDirection="BOTTOM"
									zIndex={100}
								>
									<div />
									<div />
									<div />
									<div onClick={() => this.handleClick('aboutFlag')}>
										<div className="inner-items">
											<img data-tip data-for='about' src = {require('./img/user.png')} style = {{height: this.state.iconSize,
											width: this.state.iconSize, borderRadius: '30%'}}>
											</img>
										</div>
										<div className="caption" style = {{color:'black'}}>About</div>
										<ReactTooltip className="extraClass" id='about' type='dark'>
											<span>About</span>
										</ReactTooltip>
									</div>
									<div onClick={() => this.handleClick('pubFlag')}>
										<div className="inner-items">
											<img data-tip data-for='paper' src={require('./img/feather-pen.png')} 
												style={{ height: this.state.iconSize, width: this.state.iconSize, borderRadius: '20%' }} />
										</div>
										<div className="caption" style={{ color: 'black' }} onClick={() => this.handleClick('pubFlag')}>Publication</div>
										<ReactTooltip className="extraClass" id='paper' type='dark'>
											<span>Publications</span>
										</ReactTooltip>
									</div>
									<div>
										<a href='https://anranxu.com/pdf/Anran_CV.pdf' target="_blank" style={{textDecoration: 'none'}}>
											<img data-tip data-for='resume' src = {require('./img/resume.png')} style = {{height: this.state.iconSize,
											width: this.state.iconSize, borderRadius: '20%'}}>
											</img>
											<div className="caption" style = {{color:'black'}}>Resume</div>
											<ReactTooltip className="extraClass" id='resume' type='dark'>
												<span>Resume</span>
											</ReactTooltip>
										</a>
									</div>
									<div onClick = {(e) => {this.clearFlag(); this.changeLayout(0);}}>
										<div className="inner-items">
											<img data-tip data-for='back' src = {require('./img/back.png')} style = {{height: this.state.iconSize,
												width: this.state.iconSize, borderRadius: '50%'}}
>
											</img>
										</div>
										<div className="caption" style = {{color:'black'}}>Back</div>
										<ReactTooltip className="extraClass" id='back' type='dark'>
											<span>Back</span>
										</ReactTooltip>
									</div>
						            <div />
								</Planet>
							</div>
							<div style={this.emptyDiv}>
								
							</div>
							<div className='basicInfo'>
								{this.basic()}
							</div>
							<div style = {{height: 30}} ></div>
							<Stack direction="row" spacing={5} alignItems="center" justifyContent="center">
								<Box> {/* Assuming each icon should take equal space */}
									<a href='mailto:anran@iis-lab.org'>
										<img data-tip data-for='email' src={require('./img/email.png')} style={{ height: this.state.iconSize, width: this.state.iconSize, borderRadius: '20%' }} />
									</a>
									<ReactTooltip className="extraClass" id='email' type='dark'>
										<span>Email</span>
									</ReactTooltip>
								</Box>

								<Box>
									<a href='https://github.com/AnranXu' target="_blank">
										<img data-tip data-for='git' src={require('./img/github.png')} style={{ height: this.state.iconSize, width: this.state.iconSize, borderRadius: '20%' }} />
									</a>
									<ReactTooltip className="extraClass" id='git' type='dark'>
										<span>GitHub</span>
									</ReactTooltip>
								</Box>

								<Box>
									<a href='https://twitter.com/AnranXu3' target="_blank">
										<img data-tip data-for='XTwitter' src={require('./img/X.png')} style={{ height: this.state.iconSize, width: this.state.iconSize, borderRadius: '20%' }} />
									</a>
									<ReactTooltip className="extraClass" id='XTwitter' type='dark'>
										<span>X</span>
									</ReactTooltip>
								</Box>

								<Box>
									<a href='https://scholar.google.com/citations?user=LKunPoQAAAAJ&hl=en&oi=ao' target="_blank">
										<img data-tip data-for='GoogleScholar' src={require('./img/google-scholar.png')} style={{ height: this.state.iconSize, width: this.state.iconSize, borderRadius: '20%' }} />
									</a>
									<ReactTooltip className="extraClass" id='GoogleScholar' type='dark'>
										<span>Google Scholar</span>
									</ReactTooltip>
								</Box>
							</Stack>

						</Col>
						<Col lg={12} xl ={9}>
							<About show = {this.state.aboutFlag} xlBoundary={this.xlBoundary}/>
							<Publication show = {this.state.pubFlag} xlBoundary={this.xlBoundary}/>
						</Col>
					</Row>
				</Container>
				:
				<div className = 'backPic'>
				<Container>
				    <Row>
				    	<Col xs ={0} md={3}>
			    		</Col>
			    		<Col>
			    			<div style = {this.state.mainStyle}>
						    	<Planet centerContent={
						            <img
						            	src = {this.selfie}
						                style={{
						                    height: this.state.planetSize,
						                    width: this.state.planetSize,
						                    borderRadius: '50%',
						                    backgroundColor: '#aaaaaa',
						            }}/>}
						            hideOrbit
						            open
						            dragablePlanet
					   				dragRadiusPlanet={20}
					   				orbitRadius = {this.state.orbitRadius}
									zIndex={100}
						        >
						        	<div></div>
						        	<div onClick={() => this.handleClick('aboutFlag', 1, 1)}>
						        		<img className="enter-items" src = {require('./img/user.png')} style = {{height: this.state.iconSize,
						        		width: this.state.iconSize, borderRadius: '30%'}}>
						        		</img>
						        		<div className="caption" style = {{color:'black'}}>About</div>
						        	</div>
									<div></div>
									<div></div>

									<div onClick={() => this.handleClick('pubFlag', 1, 1)}>
										<div className="inner-items">
											<img data-tip data-for='paper' src={require('./img/feather-pen.png')} 
												style={{ height: this.state.iconSize, width: this.state.iconSize, borderRadius: '20%' }} />
										</div>
										<div className="caption" style={{ color: 'black' }} onClick={() => this.handleClick('pubFlag')}>Publication</div>
										<ReactTooltip className="extraClass" id='paper' type='dark'>
											<span>Publications</span>
										</ReactTooltip>
									</div>
									<div />
									<div />
									<div />
									<div />
									<div />
									<div>
										<a href='https://anranxu.com/pdf/Anran_CV.pdf' target="_blank" style={{textDecoration: 'none'}}>
										    <img className="enter-items" src = {require('./img/resume.png')} style = {{height: this.state.iconSize,
							        		width: this.state.iconSize, borderRadius: '20%'}}>
							        		</img>
											<div className="caption" style = {{color:'black'}}>Resume</div>
										</a>
									</div>
									<div />
									<div />
									<div />
					    		</Planet>
								</div>
								<div style={this.emptyDiv}></div>
					    		<div className='basicInfo' style={{ marginTop: window.innerWidth > this.xlBoundary ? '10%':'50px', marginRight: window.innerWidth > this.xlBoundary? '5%': '0%'}}>
					    			{this.basic()}
							    </div>
				    		
			    		</Col>
			    		<Col xs ={0} md={3}>
			    			
			    		</Col>
				    </Row>
				</Container>
				<Container>
					<Row>
						<Col md ={12} xs={12}>
						    
						</Col>
					</Row>
				</Container>
				</div>
				}
				
			</div>
		);
	}
}

export default General;